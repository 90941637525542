/* eslint-disable @typescript-eslint/no-empty-function */
import React, { createContext, useContext, useEffect, useState } from 'react';

const SeoContext = createContext({
  title: '',
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setTitle: (title: string) => {},
});

export const useSeo = () => useContext(SeoContext);

type Props = {
  children: React.ReactNode;
};

export const SeoProvider = ({ children }: Props) => {
  const [title, setTitle] = useState(
    typeof document !== 'undefined' ? document.title : ''
  );

  useEffect(() => {
    if (typeof document !== 'undefined') {
      document.title = title;
    }
  }, [title]);

  return (
    <SeoContext.Provider value={{ title, setTitle }}>
      {children}
    </SeoContext.Provider>
  );
};
