/* eslint-disable react/display-name */
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import {
  Avatar as DSAvatar,
  DesignToken,
} from '@livechat/design-system-react-components';
import React, { memo } from 'react';

import { getColorIndex } from '@teamchat-shared/lib/avatar';
import { AVATAR_PLACEHOLDERS } from '@teamchat-shared/lib/constants';

const colors = [
  '#0066FF',
  '#1858B9',
  '#CC63AE',
  '#EA4EBE',
  '#822DC5',
  '#67537B',
  '#5442C2',
  '#3216DC',
  '#3286D3',
  '#326B93',
  '#33A5BE',
  '#1C5E6C',
  '#26AC7C',
  '#75A392',
  '#7B8F27',
  '#A1B161',
  '#C3A107',
  '#D38E27',
  '#FF5C00',
  '#D97A22',
  '#9D5329',
  '#B03914',
  '#715D52',
  '#755836',
];

export type AvatarShape = 'circle' | 'rounded-square';
type AvatarStatus = Extract<DSAvatarProps['status'], string>;
type DSAvatarProps = React.ComponentProps<typeof DSAvatar>;

type Props = Omit<DSAvatarProps, 'status'> & {
  status?: string;
};

const Avatar = memo((props: Props) => {
  // Map lc rounting status to DS Avatar's status
  let mappedStatus: AvatarStatus | undefined;

  if (props.status) {
    if (['accepting_chats', 'active'].includes(props.status)) {
      mappedStatus = 'available';
    } else if (['not_accepting_chats', 'inactive'].includes(props.status)) {
      mappedStatus = 'unavailable';
    } else if (props.status && props.status !== '') {
      mappedStatus = 'unknown';
    }
  }

  const colorIndex = getColorIndex(props.text, colors);
  const color = colors[colorIndex || 0];

  // Remove old avatar placeholder
  const avatarUrl =
    props.src && !AVATAR_PLACEHOLDERS.includes(props.src)
      ? props.src
      : undefined;

  const type = avatarUrl ? 'image' : 'text';

  return (
    <DSAvatar
      {...props}
      type={type}
      src={avatarUrl}
      status={mappedStatus}
      color={color}
      css={css`
        // Ovveride DS styles

        ${props.size === 'xxsmall' &&
        `
            [class*='lc-Typography-module__heading-2xs'] {
              font-size: 10px;
              line-height: 12px;
            }        
        `}

        ${props.size === 'small' &&
        `
            [class*='lc-Typography-module__heading-2xs'] {
              font-size: 15px;
              line-height: 22px;
              font-weight: 500;
            }
        `}

        [class*='lc-Avatar-module__avatar__status--xxxsmall'] {
          border-width: 1px;
          width: 6px;
          height: 6px;
        }

        [class*='lc-Avatar-module__avatar__status'] {
          // Styles for elements with class containing "lc-Avatar-module__avatar__status"
          border: 1px solid var(${DesignToken.SurfaceBasicDefault});
          top: 70%;
          left: 70%;
        }

        [class*='lc-Avatar-module__avatar__status--xxxsmall'] {
          border-width: 1px;
          width: 6px;
          height: 6px;
        }

        [class*='lc-Avatar-module__avatar__status--xxsmall'] {
          border-width: 1px;
          width: 7px;
          height: 7px;
        }

        [class*='lc-Avatar-module__avatar__status--xsmall'] {
          border-width: 1px;
          width: 8px;
          height: 8px;
        }

        [class*='lc-Avatar-module__avatar__status--small'] {
          border-width: 2px;
          width: 10px;
          height: 10px;
        }

        [class*='lc-Avatar-module__avatar__status--medium'] {
          border-width: 2px;
          width: 11px;
          height: 11px;
        }

        [class*='lc-Avatar-module__avatar__status--large'] {
          border-width: 2px;
          width: 14px;
          height: 14px;
        }

        [class*='lc-Avatar-module__avatar__status--xlarge'] {
          border-width: 2px;
          width: 18px;
          height: 18px;
        }

        [class*='lc-Avatar-module__avatar__status--xxlarge'] {
          border-width: 2px;
          width: 26px;
          height: 26px;
        }

        // Colors
        [class*='lc-Avatar-module__avatar__status--available'] {
          background: var(${DesignToken.DecorGreen400});
        }

        [class*='lc-Avatar-module__avatar__status--unavailable'] {
          background: var(${DesignToken.ActionNeutralDefault});
        }

        [class*='lc-Avatar-module__avatar__status--unknown'] {
          background: var(${DesignToken.DecorGray300});
        }
      `}
    />
  );
});

export default Avatar;
