import { LivechatAgentData } from '@livechat/hello-utils';
import { useQuery } from '@tanstack/react-query';

import api from '@teamchat-shared/lib/api';
import { QUERY_KEYS } from '@teamchat-shared/lib/constants';

export const agentKeys = {
  all: [QUERY_KEYS.AGENTS] as const,
  lists: () => [...agentKeys.all, 'list'] as const,
  details: () => [...agentKeys.all, 'detail'] as const,
  detail: (id: string) => [...agentKeys.details(), id] as const,
};

export type AgentData = LivechatAgentData & {
  status: string;
  jobTitle: string | null;
  description: string | null;
  disabled: boolean;
};

const useAgentsQuery = <TData = AgentData[]>(
  select?: (data: AgentData[]) => TData
) =>
  useQuery({
    queryKey: agentKeys.lists(),
    queryFn: () => Promise.resolve([]),
    select,
    enabled: !!api.teamChat,
  });

export const useGetAgents = () => useAgentsQuery();

const useAgentQuery = <TData = AgentData>(
  email: string,
  select?: (data: AgentData) => TData
) =>
  useQuery({
    queryKey: agentKeys.detail(email),
    queryFn: () => Promise.resolve(null as unknown as AgentData),
    enabled: !!api.teamChat && !!email,
    select,
  });

export const useGetAgent = (email: string) => useAgentQuery(email);
