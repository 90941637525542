import { useEffect, RefObject } from 'react';

function useCustomScrollbar(
  scrollContainerRef: RefObject<HTMLElement>,
  thumbRef: RefObject<HTMLElement>,
  isReversed = false
): void {
  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    const thumb = thumbRef.current;

    if (!scrollContainer || !thumb) return;

    let isDragging = false;
    let startY = 0;
    let startScrollTop = 0;

    const handleScroll = () => {
      if (!scrollContainer || !thumb) return;

      const scrollHeight = scrollContainer.scrollHeight;
      const clientHeight = scrollContainer.clientHeight;

      const scrollTop = isReversed
        ? -1 * scrollContainer.scrollTop
        : scrollContainer.scrollTop;

      if (isReversed && scrollHeight === clientHeight) {
        thumb.style.height = '0';
        thumb.style.transform = 'translateY(0)';
        return;
      }

      const maxScrollTop = scrollHeight - clientHeight;
      const thumbHeight = (clientHeight / scrollHeight) * clientHeight;
      const maxThumbPosition = clientHeight - thumbHeight;

      let thumbPosition = 0;

      if (maxScrollTop > 0) {
        const scrollProgress = isReversed
          ? (maxScrollTop - scrollTop) / maxScrollTop
          : scrollTop / maxScrollTop;
        thumbPosition = scrollProgress * maxThumbPosition;
      }

      thumb.style.height = `${thumbHeight}px`;
      thumb.style.transform = `translateY(${thumbPosition}px)`;
    };

    const onMouseDown = (e: MouseEvent) => {
      e.preventDefault();
      isDragging = true;
      startY = e.clientY;
      startScrollTop = scrollContainer.scrollTop;
      document.addEventListener('mousemove', onMouseMove);
      document.addEventListener('mouseup', onMouseUp);
      thumb.classList.add('active');
    };

    const onMouseMove = (e: MouseEvent) => {
      if (!isDragging) return;

      const deltaY = e.clientY - startY;
      const scrollHeight = scrollContainer.scrollHeight;
      const clientHeight = scrollContainer.clientHeight;

      const thumbHeight = (clientHeight / scrollHeight) * clientHeight;
      const maxThumbPosition = clientHeight - thumbHeight;
      const maxScrollTop = scrollHeight - clientHeight;

      const scrollRatio = maxScrollTop / maxThumbPosition;

      scrollContainer.scrollTop = startScrollTop + deltaY * scrollRatio;
    };

    const onMouseUp = () => {
      isDragging = false;
      thumb.classList.remove('active');
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseup', onMouseUp);
    };

    // Attach the scroll event listener
    scrollContainer.addEventListener('scroll', handleScroll);

    // Attach mousedown event to the thumb
    thumb.addEventListener('mousedown', onMouseDown);

    // Cleanup
    return () => {
      scrollContainer.removeEventListener('scroll', handleScroll);
      thumb.removeEventListener('mousedown', onMouseDown);
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseup', onMouseUp);
    };
  }, [scrollContainerRef, thumbRef, isReversed]);
}

export default useCustomScrollbar;
