export const sidebarWidth = 388;

interface Breakpoints extends Array<string> {
  sm?: string;
  md?: string;
  lg?: string;
  xl?: string;
  xxl?: string;
}

export const breakpoints: Breakpoints = [
  '320px',
  '768px',
  '1024px',
  '1280px',
  '1360px',
];

// aliases
breakpoints.sm = breakpoints[0];
breakpoints.md = breakpoints[1];
breakpoints.lg = breakpoints[2];
breakpoints.xl = breakpoints[3];
breakpoints.xxl = breakpoints[4];

const mq = (n: keyof typeof breakpoints): string =>
  `@media (min-width: ${breakpoints[n]})`;

const mqMax = (n: keyof typeof breakpoints): string =>
  `@media (max-width: ${breakpoints[n]})`;

const fontSizes = {
  message: {
    sm: '18px',
    md: '16px',
  },
};

const fontWeights = {
  message: {
    sm: 500,
    md: 500,
  },
};

export const theme = {
  breakpoints,
  mq,
  mqMax,
  space: {
    0: '0rem',
    1: '0.25rem', // 4px
    2: '0.5rem', // 8px
    3: '0.75rem', // 12px
    4: '1rem', // 16px
    5: '1.25rem', // 20px
    6: '1.5rem', // 24px
    7: '1.75rem', // 28px
    8: '2rem', // 32px
    9: '2.25rem', // 36px
    10: '2.5rem', // 40px
  },
  fontSizes,
  fontWeights,
};

export type Theme = typeof theme;
