/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  InternalChatMentionsHelper,
  LivechatAgentData,
  TextChatChannelTypeEnum,
  TextChatIncomingMessageWsPush,
  TextChatIncomingMessageWsPushData,
  TextChatMessageTypeEnum,
} from '@livechat/hello-utils';
import { QueryClient } from '@tanstack/react-query';

import { BrowserStatusContextType } from '@teamchat-shared/contexts/browserStatus';
import type { Notification } from '@teamchat-shared/contexts/notifications';
import { ChannelData, channelsKeys } from '@teamchat-shared/queries/channels';
import { sneakPeeksKeys } from '@teamchat-shared/queries/sneakPeeks';
import { Push } from '@teamchat-shared/types/push';

import {
  handleNewMessageFromOtherUser,
  updateChannelDetailsSeenTimestamp,
  addNewChannelMessage,
} from '../utils/channels';

const handleIncomingMessage = async (
  data: Push<TextChatIncomingMessageWsPush>,
  queryClient: QueryClient,
  accountId: string,
  agents: LivechatAgentData[],
  bots: LivechatAgentData[],
  addNotification: (
    type: string,
    channel: string,
    notification: Notification
  ) => void,
  activeChannelId: string,
  browserStatus: BrowserStatusContextType
) => {
  const teamMembers = [...agents, ...bots];

  const { message, channelId } = TextChatIncomingMessageWsPushData.build(
    data?.payload
  );

  const channelData: ChannelData | undefined = queryClient.getQueryData(
    channelsKeys.detail(channelId)
  );

  if (!channelData) {
    return;
  }

  const notifications = {
    isUnread: false,
    unreadDirectCount: 0,
  };

  const isDirectMessage = channelData.type === TextChatChannelTypeEnum.Direct;
  const isFromCurrentUser = accountId === message.base.authorId;

  if (!channelId) {
    return;
  }

  const author = teamMembers.find(
    (agent) => agent.id === message.base.authorId
  );
  if (!author) {
    return;
  }

  if (!isFromCurrentUser) {
    handleNewMessageFromOtherUser(
      channelData,
      message,
      author,
      addNotification,
      teamMembers,
      accountId,
      activeChannelId,
      browserStatus.isActive
    );

    notifications.isUnread = true;

    if (
      isDirectMessage ||
      InternalChatMentionsHelper.verifyIfUserWasMentionedInMessage(
        message,
        accountId
      )
    ) {
      notifications.unreadDirectCount = 1;
    }

    if (channelId === activeChannelId && browserStatus.isActive) {
      notifications.isUnread = false;
      notifications.unreadDirectCount = 0;
    }
  } else {
    updateChannelDetailsSeenTimestamp(
      channelId,
      message.base.createdAt,
      queryClient
    );
  }

  if (
    !isFromCurrentUser ||
    message.base.type === TextChatMessageTypeEnum.File
  ) {
    addNewChannelMessage(channelId, message, queryClient, notifications);
  }

  if (isFromCurrentUser) {
    const otherUser = (channelData?.members || []).find(
      (member) => member !== accountId
    );

    if (otherUser) {
      const bot = bots.find((agent) => agent.id === otherUser);

      if (bot) {
        queryClient.setQueryData(sneakPeeksKeys.detail(channelId), {
          chatId: channelId,
          text: '',
          authorId: bot.id,
        });
      }
    }
  } else {
    queryClient.removeQueries(sneakPeeksKeys.detail(channelId));
  }
};

export default handleIncomingMessage;
