import { LivechatAgentData } from '@livechat/hello-utils';

export const getAgentsLookup = (
  agents: LivechatAgentData[]
): Record<string, { name: string; email: string }> => {
  return agents.reduce((acc, agent) => {
    acc[agent.id] = {
      name: agent.name,
      email: agent.email,
    };
    return acc;
  }, {} as Record<string, { name: string; email: string }>);
};
