/* eslint-disable no-console */
import {
  LivechatReactionShortCoreEnum,
  TextChatChannel,
} from '@livechat/hello-utils';
import * as Sentry from '@sentry/nextjs';
import type { QueryClient } from '@tanstack/react-query';
import { useParams } from 'next/navigation';
import React, { useEffect, useCallback, useState, useMemo } from 'react';
import useWebSocket, { ReadyState } from 'react-use-websocket';

import { useAmplitudeContext } from '@teamchat-shared/contexts/amplitude';
import { useBrowserStatus } from '@teamchat-shared/contexts/browserStatus';
import ChatSocketContext from '@teamchat-shared/contexts/chatSocket';
import { useNotifications } from '@teamchat-shared/hooks/useNotifications';
import { config } from '@teamchat-shared/lib/config';
import {
  AMPLITUDE_EVENT_TYPES,
  CURRENT_TIMEZONE,
} from '@teamchat-shared/lib/constants';
import { getCurrentDatetimeWithMicroseconds } from '@teamchat-shared/lib/dates';
import { useGetAgents } from '@teamchat-shared/queries/agents';
import { useGetBots } from '@teamchat-shared/queries/bots';
import handleChannelCreated from '@teamchat-shared/sockets/chat/handlers/channelCreated';
import handleGetMessages from '@teamchat-shared/sockets/chat/handlers/getMessages';
import handleIncomingMessage from '@teamchat-shared/sockets/chat/handlers/incomingMessage';
import handleListChannels from '@teamchat-shared/sockets/chat/handlers/listChannels';
import handleMessagesMarkedAsSeen from '@teamchat-shared/sockets/chat/handlers/messagesMarkedAsSeen';
import handleReactionAdded from '@teamchat-shared/sockets/chat/handlers/reactionAdded';
import handleReactionRemoved from '@teamchat-shared/sockets/chat/handlers/reactionRemoved';
import handleSendMessage from '@teamchat-shared/sockets/chat/handlers/sendMessage';
import handleUserAddedToChannel from '@teamchat-shared/sockets/chat/handlers/userAddedToChannel';
import handleUserRemovedFromChannel from '@teamchat-shared/sockets/chat/handlers/userRemovedFromChannel';

const RECONNECT_ATTEMPTS = 20;

export const useChatSocket = (token: string, queryClient: QueryClient) => {
  const { trackAmplitudeEvent } = useAmplitudeContext();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);
  const [accountId, setAccountId] = useState<string>('');
  const { addNotification } = useNotifications();
  const { data: agents = [] } = useGetAgents();
  const { data: bots = [] } = useGetBots();
  const params = useParams();
  const activeChannelId = params ? String(params.channelId) : '';
  const browserStatus = useBrowserStatus();

  const pendingRequestsRef = React.useRef(new Map());
  const pendingRequests = pendingRequestsRef.current;

  const options = useMemo(
    () => ({
      share: true,
      onOpen: () => {
        const loginPayload = JSON.stringify({
          action: 'login',
          payload: {
            token: `Bearer ${token}`,
            origin_app_name: 'LiveChat',
            product_name: 'LiveChat',
          },
        });

        // Send login message
        sendSocketMessage(loginPayload);
      },
      shouldReconnect: () => true,
      retryOnError: true,
      reconnectAttempts: RECONNECT_ATTEMPTS,
      //attemptNumber will be 0 the first time it attempts to reconnect, so this equation results in a reconnect pattern of 1 second, 2 seconds, 4 seconds, 8 seconds, and then caps at 10 seconds until the maximum number of attempts is reached
      reconnectInterval: (attemptNumber: number) =>
        Math.min(Math.pow(2, attemptNumber) * 1000, 10000),
      onReconnectStop: () => {
        Sentry.captureException(
          new Error('WebSocket reconnection attempts exceeded')
        );

        // TODO: handle this better when ws cannot reconnect, this can be due to WS problems or internet connection
        window.location.reload();
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [token]
  );

  const {
    sendMessage: sendSocketMessage,
    lastMessage,
    readyState,
  } = useWebSocket(config.wsChatUrl, options, !!token);

  const clearPendingRequest = useCallback(
    (requestKey: string) => {
      if (pendingRequests.has(requestKey)) {
        clearTimeout(pendingRequests.get(requestKey));
        pendingRequests.delete(requestKey);
      }
    },
    [pendingRequests]
  );

  const getMessages = useCallback(
    (channelId: string, attempt = 1): void => {
      if (readyState !== ReadyState.OPEN || channelId === 'general') {
        return;
      }

      const payload = {
        action: 'get_messages',
        request_id: channelId,
        payload: {
          channel_id: channelId,
          sort_order: 'desc',
        },
      };

      sendSocketMessage(JSON.stringify(payload));

      // Only set the timeout if this is not a retry or retry attempts are less than 3
      if (attempt <= 3) {
        const timeout = setTimeout(() => {
          console.log(
            `Retrying getMessages for channel ${channelId}, attempt ${
              attempt + 1
            }`
          );
          getMessages(channelId, attempt + 1);
        }, 3000);

        if (!pendingRequests.has(`get_messages-${channelId}`)) {
          pendingRequests.set(`get_messages-${channelId}`, timeout);
        }
      }
    },
    [readyState, sendSocketMessage, pendingRequests]
  );

  const sendMessage = useCallback(
    (channelId: string, text: string, tempMessageId: string): void => {
      if (readyState !== ReadyState.OPEN) {
        return;
      }

      const payload = {
        action: 'send_message',
        request_id: tempMessageId,
        payload: {
          channel_id: channelId,
          message: {
            type: 'text',
            text,
          },
        },
      };

      sendSocketMessage(JSON.stringify(payload));

      trackAmplitudeEvent(AMPLITUDE_EVENT_TYPES.MESSAGE_SENT, {});
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [readyState, sendSocketMessage]
  );

  const sendFile = useCallback(
    (channelId: string, url: string): void => {
      if (readyState !== ReadyState.OPEN) {
        return;
      }

      const payload = {
        action: 'send_message',
        request_id: channelId,
        payload: {
          channel_id: channelId,
          message: {
            type: 'file',
            url,
          },
        },
      };

      sendSocketMessage(JSON.stringify(payload));

      trackAmplitudeEvent(AMPLITUDE_EVENT_TYPES.FILE_SENT, {});
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [readyState, sendSocketMessage]
  );

  const addReaction = useCallback(
    (
      channelId: string,
      messageId: string,
      shortcode: LivechatReactionShortCoreEnum
    ): void => {
      if (readyState !== ReadyState.OPEN) {
        return;
      }

      const payload = {
        request_id: channelId,
        action: 'add_reaction',
        payload: {
          channel_id: channelId,
          message_id: messageId,
          shortcode,
        },
      };

      sendSocketMessage(JSON.stringify(payload));

      trackAmplitudeEvent(AMPLITUDE_EVENT_TYPES.REACTION_SENT, {
        shortcode: shortcode,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [readyState, sendSocketMessage]
  );

  const removeReaction = useCallback(
    (
      channelId: string,
      messageId: string,
      shortcode: LivechatReactionShortCoreEnum
    ): void => {
      if (readyState !== ReadyState.OPEN) {
        return;
      }

      const payload = {
        request_id: channelId,
        action: 'remove_reaction',
        payload: {
          channel_id: channelId,
          message_id: messageId,
          shortcode,
        },
      };

      sendSocketMessage(JSON.stringify(payload));
    },
    [readyState, sendSocketMessage]
  );

  const listChannels = useCallback((): void => {
    if (readyState !== ReadyState.OPEN) {
      return;
    }

    const payload = {
      action: 'list_channels',
      payload: {},
    };

    sendSocketMessage(JSON.stringify(payload));
  }, [readyState, sendSocketMessage]);

  const markMessagesAsSeen = useCallback(
    (channelId: string): void => {
      if (readyState !== ReadyState.OPEN) {
        return;
      }

      const payload = {
        request_id: `${channelId}`,
        action: 'mark_messages_as_seen',
        payload: {
          channel_id: channelId,
          seen_up_to: getCurrentDatetimeWithMicroseconds(),
        },
      };

      sendSocketMessage(JSON.stringify(payload));
    },
    [readyState, sendSocketMessage]
  );

  useEffect(() => {
    if (lastMessage !== null) {
      const data = JSON.parse(lastMessage.data);

      switch (data.action) {
        case 'login': {
          if (data.success) {
            setIsLoggedIn(true);
            setAccountId(data.payload.account_id);
          }

          if (!isInitialized) {
            setIsInitialized(true);
          }

          break;
        }

        case 'list_channels': {
          if (data.success) {
            handleListChannels(data, queryClient);

            const channels = data.payload.map(
              (channel: TextChatChannel) => channel.channel
            );

            channels.forEach((channel: string) => {
              getMessages(channel);
            });
          }

          break;
        }

        case 'channel_created': {
          handleChannelCreated(data, accountId, queryClient);

          if (data?.payload?.channel) {
            getMessages(data?.payload?.channel);
          }

          break;
        }

        case 'user_added_to_channel': {
          handleUserAddedToChannel(data, queryClient);

          break;
        }

        case 'user_removed_from_channel': {
          handleUserRemovedFromChannel(data, queryClient);

          break;
        }

        case 'incoming_message': {
          handleIncomingMessage(
            data,
            queryClient,
            accountId,
            agents,
            bots,
            addNotification,
            activeChannelId,
            browserStatus
          );

          break;
        }

        case 'send_message': {
          handleSendMessage(data, queryClient);

          break;
        }

        case 'get_messages':
          if (data.success) {
            handleGetMessages(data, queryClient);
            clearPendingRequest(`get_messages-${data.request_id}`);
          }
          break;

        case 'reaction_added':
          handleReactionAdded(data, queryClient);
          break;

        case 'reaction_removed':
          handleReactionRemoved(data, queryClient);
          break;

        case 'messages_marked_as_seen': {
          handleMessagesMarkedAsSeen(data, queryClient);

          break;
        }

        default:
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastMessage]);

  const socketActions = useMemo(
    () => ({
      getMessages,
      sendMessage,
      sendFile,
      addReaction,
      removeReaction,
      listChannels,
      markMessagesAsSeen,
      isInitialized: readyState === ReadyState.OPEN,
      isLoggedIn,
    }),
    [
      getMessages,
      sendMessage,
      sendFile,
      addReaction,
      removeReaction,
      listChannels,
      markMessagesAsSeen,
      readyState,
      isLoggedIn,
    ]
  );

  return socketActions;
};

export function useChatSocketContext() {
  return React.useContext(ChatSocketContext);
}
