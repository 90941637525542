import {
  HelloAccountData,
  LivechatAgentData,
  TextChatChannelData,
  TextChatChannelMessageData,
  TextChatChannelTypeEnum,
} from '@livechat/hello-utils';
import { QueryClient, useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';

import { useAccount } from '@teamchat-shared/hooks/useAccount';
import api from '@teamchat-shared/lib/api';
import { QUERY_KEYS } from '@teamchat-shared/lib/constants';
import { wasUserMentioned } from '@teamchat-shared/lib/message';

import { agentKeys } from './agents';
import { botKeys } from './bots';

export const channelsKeys = {
  all: [QUERY_KEYS.CHANNELS] as const,
  lists: () => [...channelsKeys.all, 'list'] as const,
  details: () => [...channelsKeys.all, 'detail'] as const,
  detail: (id: string) => [...channelsKeys.details(), id] as const,
};

export type ChannelData = TextChatChannelData & {
  initialMessagesFetched: boolean;
  lastMessage: TextChatChannelMessageData | null;
  messages: TextChatChannelMessageData[];
  status: string;
  previousSeenUpTo: string | null;
  messagesMeta: {
    total: number;
    nextPageId: string;
    previousPageId: string;
  };
  notifications: {
    isUnread: boolean;
    unreadDirectCount: number;
  };
  disabled: boolean;
};

const useChannelQuery = (channelId: string, isEnabled: boolean) =>
  useQuery<ChannelData>({
    queryKey: channelsKeys.detail(channelId),
    queryFn: () => Promise.resolve(null as unknown as ChannelData),
    staleTime: Infinity,
    cacheTime: Infinity,
    enabled: isEnabled,
  });

export const useGetChannel = (channelId: string, isEnabled = true) =>
  useChannelQuery(channelId, isEnabled);

const useChannelsQuery = () =>
  useQuery<ChannelData[]>({
    queryKey: channelsKeys.lists(),
    queryFn: () => Promise.resolve([]),
    cacheTime: Infinity,
    staleTime: Infinity,
  });

export const useGetChannels = () => useChannelsQuery();

export const useGetChannelByName = (channelName: string) => {
  const { data: channelsData } = useGetChannels();

  if (!channelsData) {
    return null;
  }

  return channelsData.find(
    (channel) => channel.name === channelName
  ) as ChannelData;
};

export const useGetPublicChannels = () => {
  const account = useAccount();
  const { data: channelsData } = useGetChannels();

  if (!channelsData) {
    return [];
  }

  return channelsData.filter((channelData) => {
    if (channelData.type === TextChatChannelTypeEnum.Public) {
      if (channelData.name === 'general') {
        return true;
      }

      // Show only public channels when I'm a member
      if (
        channelData?.members &&
        channelData?.members?.length > 0 &&
        channelData.members.includes(account?.gaccProfile?.id)
      ) {
        return true;
      }
    }

    return false;
  });
};

export const useGetDirectChannels = () => {
  const { data: channelsData } = useGetChannels();

  if (!channelsData) {
    return [];
  }

  return channelsData.filter((channelData) => {
    if (channelData.type === TextChatChannelTypeEnum.Direct) {
      return true;
    }

    return false;
  });
};

export const useGetUnreadMessagesCount = (): {
  channelId: string;
  channelType: string;
  channelName: string;
  unreadDirectCount: number;
  isUnread: boolean;
}[] => {
  const account = useAccount();
  const profile = account?.gaccProfile;

  const { data: channelsData } = useGetChannels();

  if (!channelsData) {
    return [];
  }

  if (!profile) {
    return [];
  }

  const unreadMessagesCount = channelsData.map((channel) => {
    const lastSeenDate = channel?.seenUpTo ? new Date(channel.seenUpTo) : null;
    const profileCreatedAtDate = new Date(profile.createdAt);

    if (!lastSeenDate) {
      const { messages } = channel;
      const lastMessage = messages[0];

      if (lastMessage?.base.authorId === profile.id) {
        return {
          channelId: channel.channelId,
          channelName: channel.name,
          channelType: channel.type,
          unreadDirectCount: 0,
          isUnread: false,
        };
      }
    }

    const unreadMessages = channel.messages.filter((message) => {
      // Filter out your messages
      if (message.base.authorId === profile.id) {
        return false;
      }

      const messageDate = new Date(message.base.createdAt);

      // Check if the message is unread
      if (
        (lastSeenDate &&
          messageDate > lastSeenDate &&
          messageDate >= profileCreatedAtDate) ||
        (!lastSeenDate && messageDate >= profileCreatedAtDate)
      ) {
        return true;
      }

      return false;
    });

    const unreadMentions = unreadMessages.filter((message) =>
      wasUserMentioned(message, profile.id)
    );

    let unreadDirectCount = unreadMentions.length;

    if (channel.type === 'direct') {
      unreadDirectCount = unreadMessages.length;
    }

    return {
      channelId: channel.channelId,
      channelName: channel.name,
      channelType: channel.type,
      unreadDirectCount,
      isUnread: unreadMessages.length > 0,
    };
  });

  return unreadMessagesCount;
};

export const useGetChannelsWithFetchedMessages = () => {
  const { data: channelsData } = useGetChannels();

  if (!channelsData) {
    return [];
  }

  return channelsData.filter((channel) => channel.initialMessagesFetched);
};

export const fetchChannels = async (
  queryClient: QueryClient,
  account: HelloAccountData
) => {
  try {
    type ChannelDataWithAgent = ChannelData & {
      member: LivechatAgentData & { disabled: boolean };
      agent?: LivechatAgentData & { disabled: boolean };
      bot: LivechatAgentData;
      channelName: string;
      isUnread: boolean;
      unreadDirectCount: number;
      status: string;
    };
    const channels: ChannelDataWithAgent[] = await api.teamChat.getChannels();

    const agents = channels
      .filter(
        (channel) =>
          channel.type === 'direct' && (!!channel.member || !!channel.agent)
      )
      .map((channel) => {
        const member = channel.member || channel.agent;

        return {
          ...member,
          status: channel.status,
        };
      });

    queryClient.setQueryData(agentKeys.lists(), agents);

    agents.forEach((agent) => {
      queryClient.setQueryData(agentKeys.detail(agent.email), agent);
    });

    const bots = channels
      .filter((channel) => channel.type === 'direct' && !!channel.bot)
      .map((channel) => ({
        ...channel.bot,
        status: 'active',
      }));

    queryClient.setQueryData(botKeys.lists(), bots);

    bots.forEach((bot) => {
      queryClient.setQueryData(botKeys.detail(bot.id), bot);
    });

    const filteredChannels = channels
      .filter((channel) => {
        if (!channel.channelId) {
          return false;
        }

        if (!channel.members) {
          return true;
        }

        if (
          !channel?.members?.includes(account?.gaccProfile?.id) &&
          channel.channelName !== 'general'
        ) {
          return false;
        }

        return true;
      })
      .map((channel) => {
        const channelData = queryClient.getQueryData(
          channelsKeys.detail(channel.channelId)
        ) as ChannelData | undefined;

        return {
          initialMessagesFetched: channelData
            ? channelData.initialMessagesFetched
            : false,
          channelId: channel.channelId,
          name:
            channel?.channelName ||
            channel?.member?.id ||
            channel?.agent?.id ||
            channel?.bot?.id,
          type: channel.type,
          createdBy: channel.createdBy,
          lastMessageCreatedAt: channel.lastMessageCreatedAt,
          seenUpTo: channel.seenUpTo,
          previousSeenUpTo: '',
          members: channel.members,
          lastMessage: channel.lastMessage,
          status: channel.status,
          messages: channelData ? channelData.messages : [],
          messagesMeta: channelData
            ? channelData.messagesMeta
            : {
                total: 0,
                nextPageId: '',
                previousPageId: '',
              },
          notifications: {
            isUnread: channel.isUnread,
            unreadDirectCount: channel.unreadDirectCount,
          },
          disabled: channel?.member?.disabled || false,
        };
      });

    queryClient.setQueryData(channelsKeys.lists(), filteredChannels);

    filteredChannels.forEach((channel) => {
      queryClient.setQueryData(channelsKeys.detail(channel.channelId), channel);
    });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error fetching channels:', error);
  }
};

export const useInitChannels = () => {
  const [isFetched, setIsFetched] = useState(false);
  const queryClient = useQueryClient();
  const account = useAccount();

  useEffect(() => {
    const initChannels = async () => {
      try {
        await fetchChannels(queryClient, account);
        setIsFetched(true);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error fetching channels:', error);
      }
    };

    if (account && !isFetched) {
      initChannels();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account]);

  return {
    isFetched,
  };
};

export const useCountChannelsNotifications = (): {
  directCount: number;
  isUnread: boolean;
} => {
  const { data: channelsData } = useGetChannels();

  const directCount =
    channelsData?.reduce((acc, channel) => {
      acc += channel.notifications.unreadDirectCount;

      return acc;
    }, 0) || 0;

  const isUnread =
    channelsData?.some((channel) => channel.notifications.isUnread) || false;

  return {
    directCount,
    isUnread,
  };
};
