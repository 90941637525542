export enum ToastType {
  Error = 'error',
  Success = 'success',
  Info = 'info',
  Warning = 'warning',
}

export type Toast = {
  id: string;
  type: ToastType;
  message: string;
  detail?: string;
  removable: boolean;
};

// Updated ToastContextType with the correct function signatures
export type ToastContextType = {
  notifyError: (msg: string, detail?: string, removable?: boolean) => void;
  notifySuccess: (msg: string, detail?: string, removable?: boolean) => void;
  notifyInfo: (msg: string, detail?: string, removable?: boolean) => void;
  notifyWarning: (msg: string, detail?: string, removable?: boolean) => void;
};
