import {
  LivechatChatEventReactionData,
  TextChatChannelMessageData,
  TextChatReactionRemovedWsPush,
  TextChatReactionRemovedWsPushData,
} from '@livechat/hello-utils';
import { QueryClient } from '@tanstack/react-query';

import { ChannelData, channelsKeys } from '@teamchat-shared/queries/channels';
import { Push } from '@teamchat-shared/types/push';

const handleReactionRemoved = async (
  data: Push<TextChatReactionRemovedWsPush>,
  queryClient: QueryClient
) => {
  const { authorId, channelId, messageId, shortcode } =
    TextChatReactionRemovedWsPushData.build(data?.payload);

  queryClient.setQueryData(
    channelsKeys.detail(channelId),
    (oldChannel?: ChannelData) => {
      if (!oldChannel?.channelId) {
        return undefined;
      }

      const messages = oldChannel?.messages.map(
        (message: TextChatChannelMessageData) => {
          if (message.base.messageId === messageId) {
            const exists = message.base.reactions.some(
              (reaction) => reaction.shortcode === shortcode
            );

            if (exists) {
              const reactions = (message.base.reactions || [])
                .map((reaction) => {
                  if (reaction.shortcode === shortcode) {
                    const updatedAuthorIds = reaction.authorIds.filter(
                      (id: string) => id !== authorId
                    );

                    if (updatedAuthorIds.length > 0) {
                      return {
                        ...reaction,
                        authorIds: updatedAuthorIds,
                      };
                    }

                    return null;
                  }

                  return reaction;
                })
                .filter(Boolean) as LivechatChatEventReactionData[];

              return {
                ...message,
                base: {
                  ...message.base,
                  reactions,
                },
              };
            }

            return message;
          }

          return message;
        }
      );

      return {
        ...oldChannel,
        messages,
      };
    }
  );
};

export default handleReactionRemoved;
