import { throttle } from 'throttle-debounce';

export const playSound = async (sound: string) => {
  try {
    const audio = new Audio(sound);
    await audio.play();
    // eslint-disable-next-line no-empty
  } catch {}
};

export const throttledPlaySound = throttle(3000, (sound) => playSound(sound));
