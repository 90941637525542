import {
  TextChatChannel,
  TextChatListChannelsWsPushData,
} from '@livechat/hello-utils';
import { QueryClient } from '@tanstack/react-query';

import { channelsKeys } from '@teamchat-shared/queries/channels';
import { Push } from '@teamchat-shared/types/push';

const handleListChannels = async (
  data: Push<TextChatChannel[]>,
  queryClient: QueryClient
) => {
  const channelsData = TextChatListChannelsWsPushData.build(data?.payload);
  const additonalChannelData = {
    lastMessage: null,
    messages: [],
    messagesMeta: {
      total: 0,
      nextPageId: '',
      previousPageId: '',
    },
  };

  // For now we are resetting the whole list of channels
  queryClient.setQueryData(
    channelsKeys.lists(),
    channelsData.channels.map((channel) => ({
      ...channel,
      ...additonalChannelData,
    }))
  );

  channelsData.channels.forEach((channelData) => {
    queryClient.setQueryData(channelsKeys.detail(channelData.channelId), {
      ...channelData,
      ...additonalChannelData,
    });
  });
};

export default handleListChannels;
