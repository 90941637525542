import { emojiMap } from 'smile2emoji';

function toSnakeCase(str: string): string {
  return str.replace(/([A-Z])/g, '_$1').toLowerCase();
}

export function convertTopLevelKeysToSnakeCase(
  obj: Record<string, unknown>
): Record<string, unknown> {
  return Object.keys(obj).reduce(
    (result, key) => ({
      ...result,
      [toSnakeCase(key)]: obj[key],
    }),
    {}
  );
}

export const formatListHumanReadable = (items: string[]): string => {
  return items.reduce((acc: string, curr: string, index: number) => {
    if (index === 0) {
      return curr;
    }

    if (index === items.length - 1) {
      return `${acc} & ${curr}`;
    }

    return `${acc}, ${curr}`;
  }, '');
};

export const convertEmoticons = (text: string) => {
  /**
   * Set of the functions that are in the prototype of the object and not to convert
   */
  const objectPrototypesFunctions: Set<string> = new Set([
    '__defineGetter__',
    '__defineSetter__',
    '__lookupGetter__',
    '__lookupSetter__',
    '__proto__',
    'constructor',
    'hasOwnProperty',
    'isPrototypeOf',
    'propertyIsEnumerable',
    'toLocaleString',
    'toString',
    'valueOf',
  ]);

  const words = text && text.trimStart().split(' ');
  const newText: string[] = [];
  if (words) {
    words.forEach((word) => {
      let w = word;
      if (word in objectPrototypesFunctions) {
        w = word;
      } else if (word in emojiMap) {
        w = `{emoji:${emojiMap[word]}}`;
      }
      newText.push(w);
    });
  }
  return newText.join(' ');
};

export const stripMarkdown = (text: string): string => {
  return (
    text
      // Remove bold (**bold** or __bold__)
      .replace(/(\*\*|__)(.*?)\1/g, '$2')
      // Remove italic (*italic* or _italic_)
      .replace(/(\*|_)(.*?)\1/g, '$2')
      // Remove strikethrough (~~strikethrough~~)
      .replace(/~~(.*?)~~/g, '$1')
      // Remove inline code (`code`)
      .replace(/`(.*?)`/g, '$1')
      // Remove links ([text](url) or <url>)
      .replace(/\[([^\]]+)\]\([^)]+\)/g, '$1')
      .replace(/<([^>]+)>/g, '$1')
      // Remove headings (# Heading)
      .replace(/^#{1,6}\s+(.*)$/gm, '$1')
      // Remove blockquotes (> Quote)
      .replace(/^\s*>\s+(.*)$/gm, '$1')
      // Remove horizontal rules (--- or ***)
      .replace(/^\s*[-*]{3,}\s*$/gm, '')
      // Remove unordered list items (- Item, * Item)
      .replace(/^\s*[-*]\s+(.*)$/gm, '$1')
      // Remove ordered list items (1. Item, 2. Item)
      .replace(/^\s*\d+\.\s+(.*)$/gm, '$1')
      // Remove images (![alt](url))
      .replace(/!\[([^\]]*)\]\([^)]+\)/g, '$1')
      // Trim any remaining whitespace
      .trim()
  );
};

export const shortenUrl = (url: string) => {
  const maxLength = 140;
  if (url.length <= maxLength) return url;

  const start = url.substring(0, maxLength / 2);
  const end = url.substring(url.length - maxLength / 2, url.length);

  return `${start}[…]${end}`;
};
