import { useQuery } from '@tanstack/react-query';

import api from '@teamchat-shared/lib/api';
import { QUERY_KEYS } from '@teamchat-shared/lib/constants';

import { AgentData } from './agents';

export const botKeys = {
  all: [QUERY_KEYS.BOTS] as const,
  lists: () => [...botKeys.all, 'list'] as const,
  details: () => [...botKeys.all, 'detail'] as const,
  detail: (id: string) => [...botKeys.details(), id] as const,
};

const useBotsQuery = <TData = AgentData[]>(
  select?: (data: AgentData[]) => TData
) =>
  useQuery({
    queryKey: botKeys.lists(),
    queryFn: () => Promise.resolve([]),
    select,
    enabled: !!api.teamChat,
  });

export const useGetBots = () => useBotsQuery();

const useBotQuery = <TData = AgentData>(
  id: string,
  select?: (data: AgentData) => TData
) =>
  useQuery({
    queryKey: botKeys.detail(id),
    queryFn: () => Promise.resolve(null as unknown as AgentData),
    enabled: !!api.teamChat && !!id,
    select,
  });

export const useGetBot = (id: string) => useBotQuery(id);
