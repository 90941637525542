/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  TextChatMessagesMarkedAsSeenWsPush,
  TextChatMessagesMarkedAsSeenWsPushData,
} from '@livechat/hello-utils';
import { QueryClient } from '@tanstack/react-query';

import { Push } from '@teamchat-shared/types/push';

import { updateChannelDetailsSeenTimestamp } from '../utils/channels';

const handleMessagesMarkedAsSeen = async (
  data: Push<TextChatMessagesMarkedAsSeenWsPush>,
  queryClient: QueryClient
) => {
  const { seenUpTo, channelId } = TextChatMessagesMarkedAsSeenWsPushData.build(
    data?.payload
  );

  updateChannelDetailsSeenTimestamp(channelId, seenUpTo, queryClient);
};

export default handleMessagesMarkedAsSeen;
