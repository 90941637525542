import { LivechatSneakPeekData } from '@livechat/hello-utils';
import { useQuery } from '@tanstack/react-query';

import { QUERY_KEYS } from '@teamchat-shared/lib/constants';

export const sneakPeeksKeys = {
  all: [QUERY_KEYS.SNEAK_PEEKS] as const,
  lists: () => [...sneakPeeksKeys.all, 'list'] as const,
  details: () => [...sneakPeeksKeys.all, 'detail'] as const,
  detail: (id: string) => [...sneakPeeksKeys.details(), id] as const,
};

const useSneakPeeksQuery = <TData = LivechatSneakPeekData[]>(
  enabled = true,
  select?: (data: LivechatSneakPeekData[]) => TData
) =>
  useQuery({
    queryKey: sneakPeeksKeys.lists(),
    queryFn: () => Promise.resolve([]),
    select,
    enabled,
    staleTime: Infinity,
  });

export const useGetSneakPeeks = () => useSneakPeeksQuery(true);

const useSneakPeekQuery = <TData = LivechatSneakPeekData>(
  channelId: string,
  select?: (data: LivechatSneakPeekData) => TData
) =>
  useQuery({
    queryKey: sneakPeeksKeys.detail(channelId),
    queryFn: () => Promise.resolve(null as unknown as LivechatSneakPeekData),
    select,
    enabled: !!channelId,
    staleTime: Infinity,
  });

export const useSneakPeekForChannel = (channelId: string) =>
  useSneakPeekQuery(channelId);
