import { useRouter } from 'next/navigation';
import React, { useEffect, useRef } from 'react';

import { MAX_NOTIFICATIONS } from '@teamchat-shared/lib/constants';
import { useCountChannelsNotifications } from '@teamchat-shared/queries/channels';

import { useSeo } from './seo';

export type Notification = {
  id: string;
  title: string;
  body: string;
  isSilent?: boolean;
  pageUrl?: string;
  isDirect?: boolean;
};

type GroupedNotification = {
  [key: string]: Notification[];
};

type Notifications = {
  [type: string]: GroupedNotification;
};

export type NotificationContextValue = {
  notifications: Notifications;
  addNotification: (
    type: string,
    id: string,
    notification: Notification
  ) => void;
  clearNotifications: (type: string, id: string) => void;
  countNotifications: (type: string, id: string, isDirect: boolean) => number;
  countAllNotifications: (type: string) => number;
};

export const NotificationContext = React.createContext<
  NotificationContextValue | undefined
>(undefined);

type Props = {
  children: React.ReactNode;
};

function Notifications({ children }: Props) {
  const { title } = useSeo();

  const router = useRouter();
  const [notifications, setNotifications] = React.useState<Notifications>({});
  const intervalRef = useRef<ReturnType<typeof setInterval> | null>(null);

  const triggerBrowserNotification = async (
    title: string,
    body: string,
    pageUrl?: string
  ) => {
    if (!('Notification' in window)) {
      return;
    }

    if (Notification.permission === 'granted') {
      try {
        const notification = new Notification(title, { body });

        const onClick = (): void => {
          window.focus();
          notification.close();

          if (pageUrl) {
            router.push(pageUrl);
          }

          notification.removeEventListener('click', onClick);
        };

        notification.addEventListener('click', onClick);

        if (pageUrl) {
          notification.onclick = () => {
            window.focus();
            notification.close();
          };
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }

      return;
    }

    if (Notification.permission !== 'default') {
      return;
    }

    const permission = await Notification.requestPermission();

    if (permission === 'granted') {
      new Notification(title, { body });
    }
  };

  const addNotification = React.useCallback(
    (type: string, id: string, notification: Notification) => {
      setNotifications((prev) => ({
        ...prev,
        [type]: {
          ...prev[type],
          [id]: [...(prev[type]?.[id] || []), notification],
        },
      }));

      if (!notification.isSilent) {
        triggerBrowserNotification(
          notification.title,
          notification.body,
          notification.pageUrl
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const clearNotifications = React.useCallback((type: string, id: string) => {
    setNotifications((prev) => ({
      ...prev,
      [type]: {
        ...prev[type],
        [id]: [],
      },
    }));
  }, []);

  const countNotifications = React.useCallback(
    (type: string, id: string, isDirect: boolean) => {
      return (
        notifications[type]?.[id]?.filter((notification) => {
          if (isDirect) {
            return notification.isDirect === isDirect;
          }

          return true;
        }).length || 0
      );
    },
    [notifications]
  );

  const countAllNotifications = React.useCallback(
    (type: string) => {
      let count = 0;
      for (const id in notifications[type]) {
        count += notifications[type][id].length;
      }
      return count;
    },
    [notifications]
  );

  const { directCount: channelNotificationsCount } =
    useCountChannelsNotifications();

  useEffect(() => {
    if (channelNotificationsCount > 0) {
      let showOriginalTitle = true;

      // Set original title first
      document.title = title;

      // Change to notification title at intervals
      intervalRef.current = setInterval(() => {
        const newTitle = showOriginalTitle
          ? title
          : `(${
              channelNotificationsCount > MAX_NOTIFICATIONS
                ? `${MAX_NOTIFICATIONS}+`
                : channelNotificationsCount
            }) ${title}`;

        document.title = newTitle;

        showOriginalTitle = !showOriginalTitle;
      }, 1500);

      return () => {
        if (intervalRef.current) {
          clearInterval(intervalRef.current);
        }
      };
    } else {
      document.title = title;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channelNotificationsCount]);

  return (
    <NotificationContext.Provider
      value={{
        notifications,
        addNotification,
        clearNotifications,
        countNotifications,
        countAllNotifications,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
}

export default Notifications;
