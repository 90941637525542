import { useParams } from 'next/navigation';
import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
} from 'react';
import { useMedia } from 'react-use';

import { breakpoints } from '@teamchat-shared/styles/theme';

type SidebarContextType = {
  isVisible: boolean;
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
};

const SidebarContext = createContext<SidebarContextType | undefined>(undefined);

type Props = {
  children: ReactNode;
};

export function SidebarProvider({ children }: Props) {
  const params = useParams();
  const isMobile = useMedia(`(max-width: ${breakpoints.md})`);
  const hasChannelId = params ? params.channelId : false;
  const defaultIsVisible = isMobile ? !hasChannelId : true;

  const [isVisible, setIsVisible] = useState(defaultIsVisible);

  useEffect(() => {
    if (isMobile) {
      setIsVisible(true);
    }
  }, [isMobile]);

  return (
    <SidebarContext.Provider value={{ isVisible, setIsVisible }}>
      {children}
    </SidebarContext.Provider>
  );
}

export function useSidebarContext() {
  const context = useContext(SidebarContext);
  if (!context) {
    throw new Error('useSidebarContext must be used within a SidebarProvider');
  }
  return context;
}
