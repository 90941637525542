import {
  TextChatChannelMessageData,
  TextChatGetMessagesWsPush,
  TextChatGetMessagesWsPushData,
} from '@livechat/hello-utils';
import { QueryClient } from '@tanstack/react-query';

import { channelsKeys, ChannelData } from '@teamchat-shared/queries/channels';
import { Push } from '@teamchat-shared/types/push';

const mergeAndSortMessages = (
  existingMessages: Array<TextChatChannelMessageData>,
  newMessages: Array<TextChatChannelMessageData>
) => {
  const messageMap = new Map();

  // Add existing messages to the map
  existingMessages.forEach((message) => {
    messageMap.set(message.base.messageId, message);
  });

  // Add new messages to the map, overriding existing ones if necessary
  newMessages.forEach((message) => {
    messageMap.set(message.base.messageId, message);
  });

  // Convert map back to array and sort by createdAt
  return Array.from(messageMap.values()).sort(
    (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
  );
};

// Function to update channel data
const updateChannelData = (
  channelData: ChannelData,
  messagesData: TextChatGetMessagesWsPushData,
  lastMessage: TextChatChannelMessageData
): ChannelData => {
  const mergedMessages = mergeAndSortMessages(
    channelData.messages,
    messagesData.messages
  );

  return {
    ...channelData,
    initialMessagesFetched: true,
    lastMessage,
    messages: mergedMessages,
    messagesMeta: {
      total: messagesData.total,
      nextPageId: messagesData.nextPageId,
      previousPageId: messagesData.previousPageId,
    },
  };
};

const handleGetMessages = async (
  data: Push<TextChatGetMessagesWsPush>,
  queryClient: QueryClient
) => {
  const channelMessages = TextChatGetMessagesWsPushData.build(data?.payload);

  const lastMessage = channelMessages.messages[0];
  const channelId = data.request_id;

  if (!channelId) return;

  // Update specific channel details
  queryClient.setQueryData(
    channelsKeys.detail(channelId),
    (oldChannel?: ChannelData) =>
      oldChannel
        ? updateChannelData(oldChannel, channelMessages, lastMessage)
        : undefined
  );

  // Update all channels
  queryClient.setQueryData(
    channelsKeys.lists(),
    (oldChannels?: ChannelData[]) => {
      if (!oldChannels) return oldChannels;

      const updatedChannels = oldChannels.map((channel) =>
        channel.channelId === channelId
          ? updateChannelData(channel, channelMessages, lastMessage)
          : channel
      );

      return updatedChannels;
    }
  );
};

export default handleGetMessages;
