/* eslint-disable @typescript-eslint/no-empty-function */
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { ToastWrapper } from '@livechat/design-system-react-components';
import React, { createContext, useContext, useState, useCallback } from 'react';

import Box from '@teamchat-shared/design/Box';
import Heading from '@teamchat-shared/design/Heading';
import Text from '@teamchat-shared/design/Text';
import {
  Toast,
  ToastContextType,
  ToastType,
} from '@teamchat-shared/types/toast';

// Default state for context
const defaultState: ToastContextType = {
  notifyError: () => {},
  notifySuccess: () => {},
  notifyInfo: () => {},
  notifyWarning: () => {},
};

const ToastsContext = createContext<ToastContextType>(defaultState);

export const useToasts = () => useContext(ToastsContext);

type Props = {
  children: React.ReactNode;
};

export const ToastsProvider = ({ children }: Props) => {
  const [notifications, setToasts] = useState<Toast[]>([]);

  const removeToast = useCallback((id: string) => {
    setToasts((currentToasts) =>
      currentToasts.filter((toast) => toast.id !== id)
    );
  }, []);

  const notify = useCallback(
    (type: ToastType, msg: string, detail?: string, removable = true) => {
      const id = Date.now().toString();

      setToasts((currentToasts) => [
        ...currentToasts,
        { id, type, message: msg, detail, removable },
      ]);
      if (!removable) {
        setTimeout(() => removeToast(id), 3000);
      }
    },
    [removeToast]
  );

  const notifyError = (msg: string, detail?: string, removable = true) =>
    notify(ToastType.Error, msg, detail, removable);

  const notifySuccess = (msg: string, detail?: string, removable = true) =>
    notify(ToastType.Success, msg, detail, removable);

  const notifyInfo = (msg: string, detail?: string, removable = true) =>
    notify(ToastType.Info, msg, detail, removable);

  const notifyWarning = (msg: string, detail?: string, removable = true) =>
    notify(ToastType.Warning, msg, detail, removable);

  return (
    <ToastsContext.Provider
      value={{ notifyError, notifySuccess, notifyInfo, notifyWarning }}
    >
      <ToastWrapper
        css={css`
          z-index: 300;
        `}
        toasts={notifications.map((toast) => ({
          id: toast.id,
          kind: toast.type,
          content: (
            <Box display="flex" flexDirection="column">
              <Heading size="sm">{toast.message}</Heading>
              {toast.detail && <Text>{toast.detail}</Text>}
            </Box>
          ),
          removable: toast.removable,
          onClose: () => removeToast(toast.id),
        }))}
      />
      {children}
    </ToastsContext.Provider>
  );
};
