/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useEffect } from 'react';

const shadow = css`
  position: absolute;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 35px;
  pointer-events: none;
`;

export const shadowTopStyles = css`
  ${shadow};
  top: 0;
  background: linear-gradient(180deg, rgb(255 255 255), transparent);
`;

export const shadowBottomStyles = css`
  ${shadow};
  bottom: 0;
  background: linear-gradient(
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.8) 100%
  );
`;

export const useScrollShadows = (
  contentRef: React.RefObject<HTMLDivElement>,
  shadowTopRef: React.RefObject<HTMLDivElement>,
  shadowBottomRef: React.RefObject<HTMLDivElement>,
  isReverse?: boolean
) => {
  useEffect(() => {
    const content = contentRef.current;
    const shadowBottom = shadowBottomRef.current;

    if (content && shadowBottom) {
      const updateShadows = () => {
        let topOpacity = String(0);
        let bottomOpacity = String(0);

        const scrollTop = isReverse
          ? content.scrollTop * -1
          : content.scrollTop;
        const contentScrollHeight = content.scrollHeight - content.offsetHeight;

        if (contentScrollHeight === 0) {
          shadowBottom.style.opacity = String(0);

          return;
        }

        const currentScrollFixed = (scrollTop / contentScrollHeight).toFixed(2);
        const currentScroll = parseFloat(currentScrollFixed);

        // Scroll on top
        if (currentScroll === 0) {
          topOpacity = String(0);
          bottomOpacity = String(1);
          // Scroll at bottom
        } else if (currentScroll === 1) {
          topOpacity = String(1);
          bottomOpacity = String(0);
          // Scrolling
        } else {
          topOpacity = String(1);
          bottomOpacity = String(1);
        }

        shadowBottom.style.opacity = isReverse ? topOpacity : bottomOpacity;
      };

      // Initial update
      updateShadows();

      const handleScroll = () => {
        updateShadows();
      };

      content.addEventListener('scroll', handleScroll);

      return () => {
        content.removeEventListener('scroll', handleScroll);
      };
    }
  }, [contentRef, shadowTopRef, shadowBottomRef, isReverse]);
};
