export const getColorIndex = <T>(
  text: string,
  items: T[]
): number | undefined => {
  if (!text || items.length === 0) {
    return;
  }

  const index =
    text.split('').reduce((acc, char) => acc + char.charCodeAt(0), 0) %
    items.length;

  return index;
};
