import { LivechatAgentData } from '@livechat/hello-utils';
import { QueryClient } from '@tanstack/react-query';

import api from '@teamchat-shared/lib/api';
import { ChannelData, channelsKeys } from '@teamchat-shared/queries/channels';
import { Push } from '@teamchat-shared/types/push';

const handleUserAddedToChannel = async (
  data: Push<{
    channel_id: string;
    user_id: string;
  }>,
  queryClient: QueryClient
) => {
  const channelId = data?.payload?.channel_id;
  const userId = data?.payload?.user_id;

  let newChannel: ChannelData | undefined;

  try {
    type ChannelDataWithAgent = ChannelData & {
      agent: LivechatAgentData;
      channelName: string;
      isUnread: boolean;
      unreadDirectCount: number;
    };

    const channels: ChannelDataWithAgent[] = await api.teamChat.getChannels();
    const channel = channels.find((channel) => channel.channelId === channelId);

    if (channel) {
      newChannel = {
        channel: '',
        status: '',
        initialMessagesFetched: false,
        channelId: channel.channelId,
        name: channel?.channelName || channel?.agent?.id,
        type: channel.type,
        createdBy: channel.createdBy,
        lastMessageCreatedAt: channel.lastMessageCreatedAt,
        seenUpTo: channel.seenUpTo,
        previousSeenUpTo: '',
        members: channel.members,
        lastMessage: channel.lastMessage,
        messages: [],
        messagesMeta: {
          total: 0,
          nextPageId: '',
          previousPageId: '',
        },
        notifications: {
          isUnread: channel.isUnread,
          unreadDirectCount: channel.unreadDirectCount,
        },
        disabled: false,
      };
    }
  } catch (error) {
    console.error('Error fetching channels', error);
  }

  queryClient.setQueryData(
    channelsKeys.detail(channelId),
    (oldChannel?: ChannelData) => {
      if (!oldChannel?.channelId) {
        if (!newChannel) {
          return undefined;
        } else {
          return newChannel;
        }
      }

      const updatedMembers = [...(oldChannel.members || []), userId];

      return {
        ...oldChannel,
        members: updatedMembers,
      };
    }
  );

  queryClient.setQueryData(
    channelsKeys.lists(),
    (oldChannels: ChannelData[] = []) => {
      const filteredChannels = oldChannels.filter(
        (oldChannel: ChannelData) => oldChannel.channelId !== channelId
      );

      const oldChannel = oldChannels.find(
        (oldChannel: ChannelData) => oldChannel.channelId === channelId
      );

      if (!oldChannel) {
        if (!newChannel) {
          return oldChannels;
        } else {
          return [...oldChannels, newChannel];
        }
      }

      const updatedChannel = {
        ...oldChannel,
        members: [...(oldChannel.members || []), userId],
      };

      return [...filteredChannels, updatedChannel];
    }
  );
};

export default handleUserAddedToChannel;
