/** @jsxImportSource @emotion/react */
import { Theme, css } from '@emotion/react';
import { CannedResponse } from '@livechat/design-system-icons';
import { Icon } from '@livechat/design-system-react-components';

import Box from '@teamchat-shared/design/Box';
import { getColorIndex } from '@teamchat-shared/lib/avatar';

const sizeMap = {
  small: {
    mobileBoxSize: 38,
    mobileIconSize: 22,
    boxSize: 32,
    iconSize: 18,
  },
  medium: {
    mobileBoxSize: 48,
    mobileIconSize: 28,
    boxSize: 42,
    iconSize: 20,
  },
  large: {
    mobileBoxSize: 90,
    mobileIconSize: 48,
    boxSize: 90,
    iconSize: 48,
  },
};

const colors = [
  {
    background: `#DBEAFF`,
    icon: `#0066FF`,
  },
  {
    background: `#BBCDE8`,
    icon: `#1858B9`,
  },
  {
    background: `#ECD7E6`,
    icon: `#BD66A4`,
  },
  {
    background: `#FFE0F6`,
    icon: `#C152A2`,
  },
  {
    background: `#F2E1FF`,
    icon: `#822DC5`,
  },
  {
    background: `#D9D3DF`,
    icon: `#67537B`,
  },
  {
    background: `#DAD5F5`,
    icon: `#5442C2`,
  },
  {
    background: `#D1C9FF`,
    icon: `#5946CE`,
  },
  {
    background: `#D8E9F9`,
    icon: `#3286D3`,
  },
  {
    background: `#E7F5FF`,
    icon: `#326B93`,
  },
  {
    background: `#CBECF3`,
    icon: `#33A5BE`,
  },
  {
    background: `#C7DFE4`,
    icon: `#377683`,
  },
  {
    background: `#D2F4E8`,
    icon: `#26AC7C`,
  },
  {
    background: `#DEEEE9`,
    icon: `#4C9279`,
  },
  {
    background: `#EBF2D2`,
    icon: `#9EB63E`,
  },
  {
    background: `#E8ECD6`,
    icon: `#8C9E44`,
  },
  {
    background: `#FEF4C6`,
    icon: `#C3A107`,
  },
  {
    background: `#FFE6C0`,
    icon: `#D38E27`,
  },
  {
    background: `#FFE0CE`,
    icon: `#FF5C00`,
  },
  {
    background: `#EAD6C4`,
    icon: `#D97A22`,
  },
  {
    background: `#E5D3C9`,
    icon: `#9D5329`,
  },
  {
    background: `#F8D7CD`,
    icon: `#B03914`,
  },
];

type Props = {
  name: string;
  size: 'small' | 'medium' | 'large';
  icon?: React.ReactNode;
  customColor?: {
    background: string;
    icon: string;
  };
};

const ChannelAvatar = ({ name, size, icon, customColor }: Props) => {
  const { boxSize, iconSize, mobileBoxSize, mobileIconSize } = sizeMap[size];
  const colorIndex = getColorIndex(name, colors);
  const color = customColor || colors[colorIndex || 0];

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      width={{
        _: mobileBoxSize,
        md: boxSize,
      }}
      height={{
        _: mobileBoxSize,
        md: boxSize,
      }}
      backgroundColor={color.background}
      borderRadius="50%"
    >
      <Icon
        source={icon || CannedResponse}
        customColor={color.icon}
        css={(theme: Theme) => css`
          > svg {
            width: ${mobileIconSize}px;
            height: ${mobileIconSize}px;

            @media (min-width: ${theme.breakpoints.md}) {
              width: ${iconSize}px;
              height: ${iconSize}px;
            }
          }
        `}
      />
    </Box>
  );
};

export default ChannelAvatar;
