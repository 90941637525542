import { QueryClient } from '@tanstack/react-query';

import { ChannelData, channelsKeys } from '@teamchat-shared/queries/channels';
import { Push } from '@teamchat-shared/types/push';

const handleUserRemovedFromChannel = async (
  data: Push<{
    channel_id: string;
    user_id: string;
  }>,
  queryClient: QueryClient
) => {
  const channelId = data?.payload?.channel_id;
  const userId = data?.payload?.user_id;

  queryClient.setQueryData(
    channelsKeys.detail(channelId),
    (oldChannel?: ChannelData) => {
      if (!oldChannel?.channelId) {
        return undefined;
      }

      const updatedMembers = (oldChannel.members || [])?.filter(
        (memberId: string) => memberId !== userId
      );

      return {
        ...oldChannel,
        members: updatedMembers,
      };
    }
  );

  queryClient.setQueryData(
    channelsKeys.lists(),
    (oldChannels: ChannelData[] = []) => {
      const filteredChannels = oldChannels.filter(
        (oldChannel: ChannelData) => oldChannel.channelId !== channelId
      );

      const oldChannel = oldChannels.find(
        (oldChannel: ChannelData) => oldChannel.channelId === channelId
      );

      if (!oldChannel) {
        return oldChannels;
      }

      const currentMembers = oldChannel?.members || [];

      const updatedChannel = {
        ...oldChannel,
        members: currentMembers.filter(
          (memberId: string) => memberId !== userId
        ),
      };

      return [...filteredChannels, updatedChannel];
    }
  );
};

export default handleUserRemovedFromChannel;
