import {
  TextChatChannelMessageData,
  TextChatChannelMessageFileData,
  TextChatChannelMessageTextData,
} from '@livechat/hello-utils';

import {
  EMOJI_REGEX_GLOBAL,
  MENTIONS_EXTRA_ITEMS,
  USER_MENTION_REGEX_GLOBAL,
} from './constants';
import { convertEmoticons, stripMarkdown } from './text';

export const parseLastMessage = (
  lastMessage: TextChatChannelMessageData,
  agentsLookup: Record<string, { name: string; email: string }>
): string => {
  let lastMessageText = '';
  let extras = null;

  if (lastMessage?.base?.type === 'text') {
    extras = lastMessage.extras as TextChatChannelMessageTextData;
    lastMessageText = convertEmoticons(extras.text);
    lastMessageText = lastMessageText.replace(
      USER_MENTION_REGEX_GLOBAL,
      (_, userId) => {
        const isExtraMention = MENTIONS_EXTRA_ITEMS.find(
          (mention) => mention.id === userId
        );

        if (isExtraMention) {
          return `@${isExtraMention.name}`;
        }

        const agent = agentsLookup[userId] || '';
        return `@${agent.name}`;
      }
    );

    lastMessageText = lastMessageText.replace(
      EMOJI_REGEX_GLOBAL,
      (_, emoji) => emoji
    );

    // Strip markdown from the final text
    lastMessageText = stripMarkdown(lastMessageText);
  } else if (lastMessage?.base?.type === 'file') {
    extras = lastMessage.extras as TextChatChannelMessageFileData;

    if (extras?.contentType?.startsWith('image/')) {
      lastMessageText = '📷 Photo';
    } else {
      lastMessageText = '📎 File';
    }
  }

  return lastMessageText;
};

export const wasUserMentioned = (
  lastMessage: TextChatChannelMessageData,
  userId: string
): boolean => {
  if (lastMessage?.base?.type === 'text') {
    const extras = lastMessage.extras as TextChatChannelMessageTextData;

    const matches = Array.from(extras.text.matchAll(USER_MENTION_REGEX_GLOBAL));

    for (const match of matches) {
      if (
        match[1] === userId ||
        MENTIONS_EXTRA_ITEMS.some((item) => item.id === match[1])
      ) {
        return true;
      }
    }
  }

  return false;
};
