import { QueryClient } from '@tanstack/react-query';

import { replaceTempMessageId, removeMessage } from '../utils/channels';

const handleSendMessage = async (
  data: {
    success: boolean;
    request_id: string;
    payload: {
      message_id: string;
    };
  },
  queryClient: QueryClient
) => {
  const { success, request_id, payload } = data;

  if (!request_id) {
    return;
  }

  const parts = request_id.split(':');
  const channelId = parts[0];
  const tempMessageId = parts[1];

  if (success) {
    replaceTempMessageId(
      channelId,
      tempMessageId,
      payload.message_id,
      queryClient
    );
  } else {
    removeMessage(channelId, tempMessageId, queryClient);
  }
};

export default handleSendMessage;
