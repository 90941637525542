import { format, parseISO } from 'date-fns';

export const getCurrentDatetimeWithMicroseconds = (): string => {
  const date = new Date();
  // Convert milliseconds to microseconds and pad start with zeros to ensure six digits
  const microseconds = (date.getMilliseconds() * 1000)
    .toString()
    .padStart(6, '0');

  // Get the ISO string, slice off the 'Z' and milliseconds, then append microseconds and 'Z' again
  return date.toISOString().slice(0, -5) + '.' + microseconds + 'Z';
};

export const getFriendlyDatetime = (datetime: string): string => {
  return format(parseISO(datetime), 'HH:mm');
};
