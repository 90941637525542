import {
  TextChatChannelMessageData,
  TextChatReactionAddedWsPush,
  TextChatReactionAddedWsPushData,
} from '@livechat/hello-utils';
import { QueryClient } from '@tanstack/react-query';

import { ChannelData, channelsKeys } from '@teamchat-shared/queries/channels';
import { Push } from '@teamchat-shared/types/push';

const handleReactionAdded = async (
  data: Push<TextChatReactionAddedWsPush>,
  queryClient: QueryClient
) => {
  const { authorId, channelId, messageId, shortcode } =
    TextChatReactionAddedWsPushData.build(data?.payload);

  queryClient.setQueryData(
    channelsKeys.detail(channelId),
    (oldChannel?: ChannelData) => {
      if (!oldChannel?.channelId) {
        return undefined;
      }

      const messages = oldChannel?.messages.map(
        (message: TextChatChannelMessageData) => {
          if (message.base.messageId === messageId) {
            const exists = message.base.reactions.some(
              (reaction) => reaction.shortcode === shortcode
            );

            if (!exists) {
              return {
                ...message,
                base: {
                  ...message.base,
                  reactions: [
                    ...message.base.reactions,
                    {
                      authorIds: [authorId],
                      shortcode,
                    },
                  ],
                },
              };
            } else {
              const reactions = (message.base.reactions || []).map(
                (reaction) => {
                  if (reaction.shortcode === shortcode) {
                    const uniqueAuthorIds = new Set([
                      ...reaction.authorIds,
                      authorId,
                    ]);

                    return {
                      ...reaction,
                      authorIds: Array.from(uniqueAuthorIds),
                    };
                  }

                  return reaction;
                }
              );

              return {
                ...message,
                base: {
                  ...message.base,
                  reactions,
                },
              };
            }
          }

          return message;
        }
      );

      return {
        ...oldChannel,
        messages,
      };
    }
  );
};

export default handleReactionAdded;
