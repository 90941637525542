import { LivechatReactionShortCoreEnum } from '@livechat/hello-utils';
import React from 'react';

type ChatSocketContextType = {
  sendMessage: (
    channel: string,
    message: string,
    tempMessageId: string
  ) => void;
  sendFile: (channel: string, url: string) => void;
  getMessages: (channel: string) => void;
  addReaction: (
    channel: string,
    message: string,
    reaction: LivechatReactionShortCoreEnum
  ) => void;
  removeReaction: (
    channel: string,
    message: string,
    reaction: LivechatReactionShortCoreEnum
  ) => void;
  listChannels: () => void;
  markMessagesAsSeen(channel: string): void;
  isLoggedIn: boolean;
  isInitialized: boolean;
};

const ChatSocketContext = React.createContext<ChatSocketContextType>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  undefined as any
);

export default ChatSocketContext;
