export const CURRENT_TIMEZONE =
  Intl.DateTimeFormat().resolvedOptions().timeZone;

export const QUERY_KEYS = {
  APPS: 'apps',
  AGENTS: 'agents',
  CUSTOMERS: 'customers',
  EVENTS: 'events',
  CHATS: 'chats',
  GROUPS: 'groups',
  STATUSES: 'statuses',
  USER_CHAT: 'user_chat',
  CANNED_RESPONSES: 'canned_responses',
  DIRECT_MESSAGES: 'direct_messages',
  CHANNELS: 'channels',
  CHANNELS_DETAILS: 'channels_details',
  CUSTOMER_CHATS: 'customer_chats',
  PROPERTIES: 'properties',
  BOTS: 'bots',
  CHECK_IMAGES: 'check_images',
  SNEAK_PEEKS: 'sneak_peeks',
};

export const CHAT_STATE = {
  CHATTING: 'chatting',
};

export const PUBLIC_ROUTES = ['/demo', '/', '/join', '/redirect'];

export const CANNED_RESPONSES_PLACEHOLDER = {
  CUSTOMER_NAME: '%name%',
};

export const CHANNELS = {
  GENERAL: {
    LABEL: 'general',
    ID: 'general',
  },
};

export const NOTIFICATIONS_TYPE = {
  CHANNEL_MESSAGE: 'channel_message',
  PUBLIC_CHANNEL: 'public',
  DIRECT_CHANNEL: 'direct',
  CHAT: 'chat',
  CUSTOMER_CHAT: 'customer_chat',
};

export const SOUNDS = {
  SENDING_MESSAGE: '/sounds/sending_msg.wav',
  NEW_MESSAGE: '/sounds/new_msg.wav',
};

export const ONE_BOT = {
  avatar:
    'https://cdn.livechat-static.com/api/file/lc/img/default/assets/one.png',
  name: 'One',
};

export const ONBOARDING_STATUSES = {
  IDLE: 'idle',
  FETCHING: 'fetching',
  ERROR: 'error',
  COMPLETED: 'completed',
  REQUIRED: 'required',
};

export const MAX_ATTACHMENT_SIZE = 10;

export const TOOLTIPS = {
  RIGHT_REACTIONS: 'right-reactions',
  BOTTOM_REACTIONS: 'bottom-reactions',
};

export const RICH_MESSAGE_CUSTOM_ID = {
  INVITE_TEAM: 'invite-team',
};

export const SCAN_STATUS = {
  NOT_FOUND: 'not found',
  IN_PROGRESS: 'in progress',
  COMPLETED: 'completed',
  FAILED: 'failed',
  TIMEOUT: 'timeout',
};

export const MIN_TIME_TO_COMBINE_MESSAGES = 600000;
export const MAX_NOTIFICATIONS = 9;
export const AVATAR_PLACEHOLDERS = [
  'https://cdn.livechat-static.com/api/file/lc/img/default/avatars/ab5b0666feffd67600206cd519fd77ea.jpg',
  'https://cdn.livechat-static.com/api/file/lc/img/default/avatars/394ad6e3df2320941ab8e0ca147d5221.png',
];

export const AMPLITUDE_EVENT_TYPES = {
  MESSAGE_SENT: 'Message: sent',
  FILE_SENT: 'File: sent',
  CHANNEL_CREATED: 'Channel: created',
  REACTION_SENT: 'Reaction: sent',
};

const USER_MENTION_PATTERN =
  '\\{user_id:([a-f0-9-]+|all)(?:\\|@([a-zA-Z0-9\\s]+))?\\}';

export const USER_MENTION_REGEX_GLOBAL = new RegExp(USER_MENTION_PATTERN, 'g');
export const USER_MENTION_REGEX = new RegExp(USER_MENTION_PATTERN);

const EMOJI_PATTERN = '\\{emoji:([^}]*)\\}';

export const EMOJI_REGEX_GLOBAL = new RegExp(EMOJI_PATTERN, 'g');
export const EMOJI_REGEX = new RegExp(EMOJI_PATTERN);

export const MENTIONS_EXTRA_ITEMS = [
  {
    description: '(notify everyone in this channel)',
    name: 'all',
    id: 'all',
    hasIcon: true,
  },
];
