import {
  InternalChatMentionsHelper,
  LivechatAgentData,
  LivechatChatEventReactionData,
  LivechatReactionShortCoreEnum,
  TextChatChannelMessageData,
  TextChatChannelTypeEnum,
} from '@livechat/hello-utils';
import { QueryClient } from '@tanstack/react-query';

import { Notification } from '@teamchat-shared/contexts/notifications';
import { getAgentsLookup } from '@teamchat-shared/lib/agent';
import { NOTIFICATIONS_TYPE, SOUNDS } from '@teamchat-shared/lib/constants';
import { parseLastMessage } from '@teamchat-shared/lib/message';
import { playSound } from '@teamchat-shared/lib/sounds';
import { ChannelData, channelsKeys } from '@teamchat-shared/queries/channels';

/**
 * Determines if a notification should be silent based on various conditions.
 */
const shouldNotify = (
  channelId: string,
  activeChannelId: string,
  isBrowserActive: boolean,
  isDirectMessage: boolean,
  userMentioned: boolean
): boolean => {
  // User is in the current channel, no need to notify
  let skipNotification = channelId === activeChannelId;

  // User is in the current channel, but browser/tab is not active, we will notify
  if (skipNotification && !isBrowserActive) {
    skipNotification = false;
  }

  // Before we show notification, we need to check if it's a direct message or user was mentioned
  if (!skipNotification) {
    skipNotification = !isDirectMessage && !userMentioned;
  }

  return !skipNotification;
};

export const handleNewMessageFromOtherUser = (
  channel: ChannelData,
  message: TextChatChannelMessageData,
  author: LivechatAgentData,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  addNotification: (
    type: string,
    channel: string,
    notification: Notification
  ) => void,
  agents: LivechatAgentData[],
  accountId: string,
  activeChannelId: string,
  isBrowserActive: boolean
) => {
  const isDirectMessage = channel.type === TextChatChannelTypeEnum.Direct;
  const title = isDirectMessage
    ? author?.name || ''
    : `#${channel.name}: ${author?.name || ''}`;
  const agentsLookup = getAgentsLookup(agents);
  const userMentioned =
    InternalChatMentionsHelper.verifyIfUserWasMentionedInMessage(
      message,
      accountId
    );
  const notificationType =
    channel.type === TextChatChannelTypeEnum.Public
      ? NOTIFICATIONS_TYPE.CHANNEL_MESSAGE
      : NOTIFICATIONS_TYPE.DIRECT_CHANNEL;

  const showNotification = shouldNotify(
    channel.channelId,
    activeChannelId,
    isBrowserActive,
    isDirectMessage,
    userMentioned
  );

  addNotification(notificationType, channel.channelId, {
    id: message.base.messageId,
    title,
    body:
      'text' in message.extras ? parseLastMessage(message, agentsLookup) : '',
    pageUrl: `/app/${isDirectMessage ? 'team' : 'channel'}/${
      channel.channelId
    }`,
    isSilent: !showNotification,
  });

  playSound(SOUNDS.NEW_MESSAGE);
};

export const updateChannelDetailsSeenTimestamp = (
  channelId: string,
  timestamp: string,
  queryClient: QueryClient
) => {
  const updateChannel = (oldChannel: ChannelData) => {
    return {
      ...oldChannel,
      seenUpTo: timestamp,
      // We are assuming that all messages are read
      // TODO: this need to be updated when introducing marking as unread
      notifications: {
        isUnread: false,
        unreadDirectCount: 0,
      },
    };
  };

  queryClient.setQueryData(
    channelsKeys.lists(),
    (oldChannels: ChannelData[] = []) => {
      const filteredChannels = oldChannels.filter(
        (oldChannel: ChannelData) => oldChannel.channelId !== channelId
      );

      const oldChannel = oldChannels.find(
        (oldChannel: ChannelData) => oldChannel.channelId === channelId
      );

      if (!oldChannel) {
        return oldChannels;
      }

      return [...filteredChannels, updateChannel(oldChannel)];
    }
  );

  queryClient.setQueryData(
    channelsKeys.detail(channelId),
    (oldChannel?: ChannelData) => {
      if (!oldChannel?.channelId) {
        return undefined;
      }

      return updateChannel(oldChannel);
    }
  );
};

export const addReactionOptimistic = (
  channelId: string,
  messageId: string,
  shortcode: LivechatReactionShortCoreEnum,
  authorId: string,
  queryClient: QueryClient
) => {
  queryClient.setQueryData(
    channelsKeys.detail(channelId),
    (oldChannel?: ChannelData) => {
      if (!oldChannel?.channelId) {
        return undefined;
      }

      const messages = oldChannel?.messages.map(
        (message: TextChatChannelMessageData) => {
          if (message.base.messageId === messageId) {
            const exists = message.base.reactions.some(
              (reaction) => reaction.shortcode === shortcode
            );

            if (!exists) {
              return {
                ...message,
                base: {
                  ...message.base,
                  reactions: [
                    ...message.base.reactions,
                    {
                      authorIds: [authorId],
                      shortcode,
                    },
                  ],
                },
              };
            } else {
              const reactions = (message.base.reactions || []).map(
                (reaction) => {
                  if (reaction.shortcode === shortcode) {
                    return {
                      ...reaction,
                      authorIds: [...reaction.authorIds, authorId],
                    };
                  }

                  return reaction;
                }
              );

              return {
                ...message,
                base: {
                  ...message.base,
                  reactions,
                },
              };
            }
          }

          return message;
        }
      );

      return {
        ...oldChannel,
        messages,
      };
    }
  );
};

export const removeReactionOptimistic = (
  channelId: string,
  messageId: string,
  shortcode: LivechatReactionShortCoreEnum,
  authorId: string,
  queryClient: QueryClient
) => {
  queryClient.setQueryData(
    channelsKeys.detail(channelId),
    (oldChannel?: ChannelData) => {
      if (!oldChannel?.channelId) {
        return undefined;
      }

      const messages = oldChannel?.messages.map(
        (message: TextChatChannelMessageData) => {
          if (message.base.messageId === messageId) {
            const reactions = (message.base.reactions || [])
              .map((reaction) => {
                if (reaction.shortcode === shortcode) {
                  const updatedAuthorIds = reaction.authorIds.filter(
                    (id) => id !== authorId
                  );

                  if (updatedAuthorIds.length === 0) {
                    return null;
                  }

                  return {
                    ...reaction,
                    authorIds: updatedAuthorIds,
                  };
                }

                return reaction;
              })
              .filter(Boolean) as LivechatChatEventReactionData[];

            return {
              ...message,
              base: {
                ...message.base,
                reactions,
              },
            };
          }

          return message;
        }
      );

      return {
        ...oldChannel,
        messages,
      };
    }
  );
};

export const addNewChannelMessage = (
  channelId: string,
  message: TextChatChannelMessageData,
  queryClient: QueryClient,
  notifications: {
    isUnread: boolean;
    unreadDirectCount: number;
  }
) => {
  queryClient.setQueryData(
    channelsKeys.detail(channelId),
    (oldChannel?: ChannelData) => {
      if (!oldChannel) {
        return undefined;
      }

      const messages = oldChannel?.messages || [];

      return {
        ...oldChannel,
        lastMessage: message,
        messages: [message, ...messages],
        notifications: {
          isUnread: notifications.isUnread,
          unreadDirectCount:
            oldChannel.notifications.unreadDirectCount +
            notifications.unreadDirectCount,
        },
      };
    }
  );

  queryClient.setQueryData(
    channelsKeys.lists(),
    (oldChannels: ChannelData[] = []) => {
      const filteredChannels = oldChannels.filter(
        (oldChannel: ChannelData) => oldChannel.channelId !== channelId
      );

      const oldChannel = oldChannels.find(
        (oldChannel: ChannelData) => oldChannel.channelId === channelId
      );

      if (!oldChannel) {
        return oldChannels;
      }

      oldChannel.messages.unshift(message);
      oldChannel.lastMessage = message;

      oldChannel.notifications = {
        isUnread: notifications.isUnread,
        unreadDirectCount:
          oldChannel.notifications.unreadDirectCount +
          notifications.unreadDirectCount,
      };

      return [...filteredChannels, oldChannel];
    }
  );
};

export const replaceTempMessageId = (
  channelId: string,
  tempMessageId: string,
  newMessageId: string,
  queryClient: QueryClient
) => {
  queryClient.setQueryData(
    channelsKeys.detail(channelId),
    (oldChannel?: ChannelData) => {
      if (!oldChannel?.channelId) {
        return undefined;
      }

      const updatedMessages = oldChannel.messages.map((msg) =>
        msg.base.messageId === tempMessageId
          ? { ...msg, messageId: newMessageId }
          : msg
      );

      return {
        ...oldChannel,
        messages: updatedMessages,
      };
    }
  );

  queryClient.setQueryData(
    channelsKeys.lists(),
    (oldChannels: ChannelData[] = []) => {
      const filteredChannels = oldChannels.filter(
        (oldChannel: ChannelData) => oldChannel.channelId !== channelId
      );

      const oldChannel = oldChannels.find(
        (oldChannel: ChannelData) => oldChannel.channelId === channelId
      );

      if (!oldChannel) {
        return oldChannels;
      }

      const updatedMessages = oldChannel.messages.map((msg) =>
        msg.base.messageId === tempMessageId
          ? { ...msg, messageId: newMessageId }
          : msg
      );

      const updatedChannel = {
        ...oldChannel,
        messages: updatedMessages,
      };

      return [...filteredChannels, updatedChannel];
    }
  );
};

export const removeMessage = (
  channelId: string,
  messageId: string,
  queryClient: QueryClient
) => {
  queryClient.setQueryData(
    channelsKeys.detail(channelId),
    (oldChannel?: ChannelData) => {
      if (!oldChannel?.channelId) {
        return undefined;
      }

      const updatedMessages = oldChannel.messages.filter(
        (msg) => msg.base.messageId !== messageId
      );

      return {
        ...oldChannel,
        messages: updatedMessages,
      };
    }
  );

  queryClient.setQueryData(
    channelsKeys.lists(),
    (oldChannels: ChannelData[] = []) => {
      const filteredChannels = oldChannels.filter(
        (oldChannel: ChannelData) => oldChannel.channelId !== channelId
      );

      const oldChannel = oldChannels.find(
        (oldChannel: ChannelData) => oldChannel.channelId === channelId
      );

      if (!oldChannel) {
        return oldChannels;
      }

      const updatedMessages = oldChannel.messages.filter(
        (msg) => msg.base.messageId !== messageId
      );

      const updatedChannel = {
        ...oldChannel,
        messages: updatedMessages,
      };

      return [...filteredChannels, updatedChannel];
    }
  );
};

export const updateChannelMessages = (
  channelId: string,
  messages: TextChatChannelMessageData[],
  meta: {
    nextPageId: string;
    previousPageId: string;
  },
  queryClient: QueryClient
) => {
  queryClient.setQueryData(
    channelsKeys.detail(channelId),
    (oldChannel?: ChannelData) => {
      if (!oldChannel?.channelId) {
        return undefined;
      }

      return {
        ...oldChannel,
        messages: [...oldChannel.messages, ...messages],
        messagesMeta: {
          total: oldChannel?.messagesMeta?.total || 0,
          nextPageId: meta.nextPageId,
          previousPageId: meta.previousPageId,
        },
      };
    }
  );

  queryClient.setQueryData(
    channelsKeys.lists(),
    (oldChannels: ChannelData[] = []) => {
      // Find the index of the channel to update
      const channelIndex = oldChannels.findIndex(
        (channel) => channel.channelId === channelId
      );

      if (channelIndex === -1) {
        return oldChannels;
      }

      // Create a new array with the updated channel data
      const updatedChannels = [...oldChannels];
      updatedChannels[channelIndex] = {
        ...oldChannels[channelIndex],
        messages: [...oldChannels[channelIndex].messages, ...messages],
        messagesMeta: {
          total: oldChannels[channelIndex]?.messagesMeta?.total || 0,
          nextPageId: meta.nextPageId,
          previousPageId: meta.previousPageId,
        },
      };

      return updatedChannels;
    }
  );
};

export const updatePreviousSeenTimestamp = (
  channelId: string,
  timestamp: string | null,
  queryClient: QueryClient
) => {
  const updateChannel = (oldChannel: ChannelData) => {
    return {
      ...oldChannel,
      previousSeenUpTo: timestamp,
    };
  };

  queryClient.setQueryData(
    channelsKeys.lists(),
    (oldChannels: ChannelData[] = []) => {
      const filteredChannels = oldChannels.filter(
        (oldChannel: ChannelData) => oldChannel.channelId !== channelId
      );

      const oldChannel = oldChannels.find(
        (oldChannel: ChannelData) => oldChannel.channelId === channelId
      );

      if (!oldChannel) {
        return oldChannels;
      }

      return [...filteredChannels, updateChannel(oldChannel)];
    }
  );

  queryClient.setQueryData(
    channelsKeys.detail(channelId),
    (oldChannel?: ChannelData) => {
      if (!oldChannel?.channelId) {
        return undefined;
      }

      return updateChannel(oldChannel);
    }
  );
};
