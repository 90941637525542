/* eslint-disable react/display-name */
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { DesignToken } from '@livechat/design-system-react-components';
import { useRef, forwardRef } from 'react';

import useCustomScrollbar from '@teamchat-shared/hooks/useCustomScrollbar';

type Props = {
  children: React.ReactNode;
  className?: string;
};

const containerStyles = css`
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
`;

export const scrollContentStyles = css`
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;

  /* Hide native scrollbar */
  &::-webkit-scrollbar {
    display: none;
  }

  scrollbar-width: none; /* For Firefox */
`;

export const scrollbarThumbStyles = css`
  position: absolute;
  top: 0;
  right: 2px;
  width: 8px;
  background-color: var(${DesignToken.BorderBasicPrimary});
  border-radius: 4px;
  transition: background-color 0.2s, opacity 0.2s;
  z-index: 1;
  opacity: 0;

  &.hover,
  &:hover {
    background-color: var(${DesignToken.BorderBasicPrimary});
    opacity: 1;
  }

  &.active {
    background-color: var(${DesignToken.BorderBasicPrimary});
  }
`;

const CustomScrollbar = forwardRef<HTMLDivElement, Props>(
  ({ children, className }, ref) => {
    const internalContentRef = useRef<HTMLDivElement>(null);
    const scrollContainerRef =
      (ref as React.RefObject<HTMLDivElement>) || internalContentRef;

    const scrollThumbRef = useRef<HTMLDivElement>(null);
    useCustomScrollbar(scrollContainerRef, scrollThumbRef, false);

    return (
      <div
        css={[containerStyles, className]}
        onMouseEnter={() => scrollThumbRef.current?.classList.add('hover')}
        onMouseLeave={() => scrollThumbRef.current?.classList.remove('hover')}
      >
        <div
          css={scrollContentStyles}
          ref={scrollContainerRef}
          id="custom-scrollbar-content"
        >
          {children}
        </div>
        <div css={scrollbarThumbStyles} ref={scrollThumbRef}></div>
      </div>
    );
  }
);

export default CustomScrollbar;
