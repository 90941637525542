import { localStorage } from '@teamchat-shared/lib/storage';

import {
  EMOJI_REGEX_GLOBAL,
  MENTIONS_EXTRA_ITEMS,
  USER_MENTION_REGEX_GLOBAL,
} from './constants';
import { getCurrentDatetimeWithMicroseconds } from './dates';
import { stripMarkdown } from './text';

// For now we are using localStorage to store drafts
// in the future we may want to use some storage and handle files

export const removeDraft = (channelId: string) => {
  localStorage.removeItem(`draft_${channelId}`);
};

export const getDraft = (channelId: string) => {
  const draft = localStorage.getItem(`draft_${channelId}`);

  try {
    return draft ? JSON.parse(draft) : { text: '', updatedAt: '' };
  } catch (error) {
    removeDraft(channelId);
    return { text: '', updatedAt: '' };
  }
};

export const updateDraft = (channelId: string, text: string) => {
  if (typeof window !== 'undefined') {
    const existingDraft = getDraft(channelId);
    if (existingDraft.text !== text) {
      const trimmedText = text.trim();

      const draft = {
        text: trimmedText,
        updatedAt: getCurrentDatetimeWithMicroseconds(),
      };
      localStorage.setItem(`draft_${channelId}`, JSON.stringify(draft));
      window.dispatchEvent(new Event(`storage_${channelId}`));
    }
  }
};

export const parseDraftText = (
  draftText: string,
  agentsLookup: Record<string, { name: string; email: string }>
) => {
  let parsedText = draftText.replace(USER_MENTION_REGEX_GLOBAL, (_, userId) => {
    const isExtraMention = MENTIONS_EXTRA_ITEMS.find(
      (mention) => mention.id === userId
    );

    if (isExtraMention) {
      return `@${isExtraMention.name}`;
    }

    const agent = agentsLookup[userId] || '';
    return `@${agent.name}`;
  });

  parsedText = parsedText.replace(EMOJI_REGEX_GLOBAL, (_, emoji) => emoji);

  parsedText = stripMarkdown(parsedText);
  return parsedText;
};
