import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from 'react';

export type BrowserStatusContextType = {
  isActive: boolean;
  setIsActive: React.Dispatch<React.SetStateAction<boolean>>;
};

const BrowserStatusContext = createContext<BrowserStatusContextType>({
  isActive: true,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setIsActive: () => {},
});

export const useBrowserStatus = () => useContext(BrowserStatusContext);

type Props = {
  children: ReactNode;
};

export function BrowserStatusProvider({ children }: Props) {
  const [isActive, setIsActive] = useState<boolean>(true);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (!document.hidden) {
        // Add 500ms delay when changing from inactive to active
        setTimeout(() => {
          setIsActive(true);
        }, 500);
      } else {
        setIsActive(false);
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  return (
    <BrowserStatusContext.Provider value={{ isActive, setIsActive }}>
      {children}
    </BrowserStatusContext.Provider>
  );
}
