/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { DesignToken } from '@livechat/design-system-react-components';

import { Theme, sidebarWidth } from '@teamchat-shared/styles/theme';

export const layoutStyles = css`
  display: flex;
  height: 100dvh;
`;

export const headersStyles = css`
  display: flex;
  align-items: center;
  padding: 16px 28px 16px 30px;
  position: relative;
  z-index: 20;
  border-bottom: 1px solid var(${DesignToken.BorderBasicSecondary});
`;

export const sidebarStyles = (theme: Theme) =>
  css`
    width: 100%;
    min-width: 100%;
    height: 100%;
    background-color: var(${DesignToken.SurfaceBasicDefault});
    border-right: 1px solid var(${DesignToken.BorderBasicSecondary});
    display: flex;
    flex-direction: column;
    overflow-y: hidden;
    overflow-x: hidden;
    background-color: transparent;
    position: relative;

    ${theme.mq('md')} {
      overflow-y: auto;
      flex: 0 0 ${sidebarWidth}px;
      max-width: ${sidebarWidth}px;
      min-width: auto;
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
    }
  `;

export const mainStyles = (theme: Theme) =>
  css`
    display: flex;
    flex-direction: column;
    min-width: 100%;
    background-color: var(${DesignToken.SurfaceBasicDefault});
    position: relative;
    z-index: 200;

    ${theme.mq('md')} {
      flex-grow: 1;
      height: 100%;
      overflow: hidden;
      min-width: auto;
      flex-basis: 100%;
      background-color: transparent;
    }
  `;

export const logoStyles = (theme: Theme) => css`
  height: 35px;
  width: auto;

  ${theme.mq('md')} {
    height: 23px;
  }
`;
