import {
  TextChatChannel,
  TextChatChannelData,
  TextChatChannelTypeEnum,
} from '@livechat/hello-utils';
import { QueryClient } from '@tanstack/react-query';

import { getCurrentDatetimeWithMicroseconds } from '@teamchat-shared/lib/dates';
import { localStorage } from '@teamchat-shared/lib/storage';
import { ChannelData, channelsKeys } from '@teamchat-shared/queries/channels';
import { Push } from '@teamchat-shared/types/push';

const handleChannelCreated = async (
  data: Push<TextChatChannel>,
  accountId: string,
  queryClient: QueryClient
) => {
  const channelData = TextChatChannelData.build(data?.payload);
  const additonalChannelData = {
    status: '',
    previousSeenUpTo: '',
    initialMessagesFetched: false,
    lastMessage: null,
    messages: [],
    messagesMeta: {
      total: 0,
      nextPageId: '',
      previousPageId: '',
    },
    notifications: {
      isUnread: false,
      unreadDirectCount: 0,
    },
    disabled: false,
  };

  let channelName = '';
  if (channelData.type === TextChatChannelTypeEnum.Direct) {
    const agentId = (channelData?.members || []).find(
      (member) => member !== accountId
    );

    // Edge case for personal notes
    if (
      !agentId &&
      (channelData?.members || []).every((member) => member === accountId)
    ) {
      channelName = accountId;
    } else {
      channelName = agentId as string;
    }
  } else {
    channelName = channelData.name;
  }

  // TODO: this will be moved to api
  const createdAt = getCurrentDatetimeWithMicroseconds();
  localStorage.setItem(`channel-${channelData.channelId}-createdAt`, createdAt);

  const newChannel: ChannelData = {
    ...channelData,
    name: channelName,
    ...additonalChannelData,
  };

  queryClient.setQueryData(
    channelsKeys.detail(newChannel.channelId),
    newChannel
  );

  queryClient.setQueryData(
    channelsKeys.lists(),
    (oldChannels: ChannelData[] = []) => {
      const filteredChannels = oldChannels.filter(
        (oldChannel) => oldChannel.channelId !== newChannel.channelId
      );

      return [...filteredChannels, newChannel];
    }
  );
};

export default handleChannelCreated;
