import styled from '@emotion/styled';
import { Input as DSInput } from '@livechat/design-system-react-components';
import {
  layout,
  color,
  border,
  typography,
  LayoutProps,
  BorderProps,
  ColorProps,
  TypographyProps,
} from 'styled-system';

interface Props extends TypographyProps, ColorProps, LayoutProps, BorderProps {
  as?: React.ElementType;
}

const Input = styled(DSInput)<Props>`
  ${border}
  ${layout}
  ${color}
  ${typography}
`;

export default Input;
